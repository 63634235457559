<template>
  <!-- This will act as a layout wrapper for child routes -->
  <TsHeader data-testid="header" />

  <div>
    <slot />
  </div>

  <TsProductActions
    v-if="config.public.featureFlags.enableCTAAddToTrolleyBuyNow"
  />

  <TsProductActionsv2
    v-else-if="config.public.featureFlags.enableCTACollectionDelivery"
  />
  <TsFooter data-testid="footer" />
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();

const algoliaStore = useAlgoliaStore();
const config = useRuntimeConfig();


const route = useRoute();


onMounted(async () => {
});


</script>
